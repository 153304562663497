<template>
  <div>
    <v-card class="mt-1 mx-2 mb-1 elevation-5 rounded-lg">
      <KpiMainMap
        :filterKabupaten="filterKabupaten"
        :itemModified="itemModified"
        :itemsFile="itemsFile"
      ></KpiMainMap>

    </v-card>

    <InfoDialog
        :infoDialogMessage="infoDialogMessage"
        ref="infoDialog"
    ></InfoDialog>


 </div>
</template>

<script>

import KpiMainMap from "@/components/public_kpi/KpiMainMap";
import InfoDialog from "@/components/utils/InfoDialog";
import FAreaService from "@/services/apiservices/f-area-service";
import FSectorTypeService from "@/services/apiservices/f-sector-type-service";
import FKomoditiTypeService from "@/services/apiservices/f-komoditi-type-service";
import FInfrastructureTypeService from "@/services/apiservices/f-infrastructure-type-service";
import FSubAreaService from "@/services/apiservices/f-sub-area-service";

export default {
  name: 'KpiMain',
  props:{
    jenis: String
  },
  components: {
    InfoDialog,
    KpiMainMap
  },
  data(){
    return {
      showInfo : true,
      infoDialogMessage: '',
      filterKabupaten: '',
      itemModified: '',
      itemsFile: []

    }
  },
  computed:{
    fAreaFiltered(){
      return this.$store.state.potensi.itemsFArea
    }
  },
  methods:{
    dialogInfoShow(){
      this.infoDialogMessage = 'hello bos oke deh'
      this.$refs.infoDialog.showDialog()
    },

    fetchParent(){
      FAreaService.getAllFAreaPublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFArea', response.data)
          },
          error => {
            console.log(error.response)
          }
      )
      FSubAreaService.getAllFSubAreaPublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFSubArea', response.data)
          },
          error => {
            console.log(error.response)
          }
      )

      FSectorTypeService.getAllFSectorTypePublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFSectorType', response.data)
          },
          error => {
            console.log(error.response)
          }
      )
      FKomoditiTypeService.getAllFKomoditiTypePublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFKomoditiType', response.data)
          },
          error => {
            console.log(error.response)
          }
      )

      FInfrastructureTypeService.getAllFInfrastructureTypePublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFInfrastructureType', response.data)
          },
          error => {
            console.log(error.response)
          })


    }

  },
  mounted() {
    this.fetchParent()
  },


}
</script>

<style scoped>
a {
  background-image: linear-gradient(
      to right,
      #54b3d6,
      #54b3d6 50%,
      #000 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

a:before{
  content: '';
  background: #54b3d6;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

a:hover {
  background-position: 0;
}

a:hover::before{
  width: 100%;
}

/* Presentational Styles */
body {
  display: grid;
  font-family: 'Poppins', sans-serif;
  font-size: 27px;
  font-weight: 700;
  height: 100vh;
  place-items: center;
}

</style>