<template>
  <div class="fill-height">
    <KpiMain
        jenis="KPI"
    ></KpiMain>
  </div>
</template>

<script>
import KpiMain from "../../components/public_kpi/KpiMain";

export default {
  name: "KpiView",
  components:{
    KpiMain
  }
}
</script>

<style scoped>

</style>